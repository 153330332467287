import request from '@/utils/request'

export const getList = (page,size,params)=>{
    return request({
        url:'/api/hado/user/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const getDetail = (id)=>{
    return request({
        url:'/api/hado/user/detail',
        method:'get',
        params:{id}
    })
}

export const refreshLevel = ()=>{
    return request({
        url:'/api/hado/user/refresh-level',
        method:'get',
    })
}

export const getRefreshLevelStatus = ()=>{
    return request({
        url:'/api/hado/user/get-refresh-level-status',
        method:'get'
    })
}

export const addScore = (data)=>{
    return request({
        url:'/api/hado/user/add-score',
        method:'post',
        data:data
    })
}

export const changeSingleLimit = (data)=>{
    return request({
        url:'/api/hado/user/change-single-limit',
        method:'post',
        data:data
    })
}

export const updateContact = (data)=>{
    return request({
        url:'/api/hado/user/update-contact',
        method:'post',
        data:data
    })
}
