<template>
    <div>
        <div>
            <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
                <el-row>
                    <el-col :span="4">
                        <el-form-item label="阵营:">
                            <el-radio-group v-model="search_option.camp">
                                <el-radio-button label="all">全部</el-radio-button>
                                <el-radio-button label="red">红方</el-radio-button>
                                <el-radio-button label="blue">蓝方</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="性别:">
                            <el-radio-group v-model="search_option.gender">
                                <el-radio-button label="all">全部</el-radio-button>
                                <el-radio-button label="unknow">未知</el-radio-button>
                                <el-radio-button label="male">男</el-radio-button>
                                <el-radio-button label="female">女</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4"><el-form-item label="ID:"><el-input clearable size="mini" v-model="search_option.id"></el-input></el-form-item></el-col>
                    <el-col :span="4"><el-form-item label="OpenID:"><el-input clearable v-model="search_option.open_id"></el-input></el-form-item></el-col>
                    <el-col :span="4"><el-form-item label="UnionID:"><el-input clearable v-model="search_option.union_id"></el-input></el-form-item></el-col>
                    <el-col :span="4"><el-form-item label="昵称:"><el-input clearable v-model="search_option.nick_name"></el-input></el-form-item></el-col>
                    <el-col :span="4"><el-form-item label="真实姓名:"><el-input clearable v-model="search_option.real_name"></el-input></el-form-item></el-col>
                    <el-col :span="4"><el-form-item label="地区:"><el-input clearable v-model="search_option.region"></el-input></el-form-item></el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                        <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div style="margin-top: 16px;">
            <el-table :data="page_list" size="mini" border @selection-change="handleSelectionChange" @current-change="handleCurrentSelectChange">
                <el-table-column v-if="mode === 'single'" width="36">
                    <template #default="scope">
                        <el-radio :label="scope.row" v-model="singleSelect"><i></i></el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="ID" prop="id" width="150"></el-table-column>
                <el-table-column label="头像" prop="avatar" width="49">
                    <template #default="scope">
                        <el-avatar :src="scope.row.avatar" size="small"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column label="昵称" prop="nick_name"></el-table-column>
                <el-table-column label="真实姓名" prop="real_name"></el-table-column>
                <el-table-column label="性别" prop="gender">
                    <template #default="scope">
                        <span :style="{color:params_map['gender']?params_map['gender'][scope.row.gender].color:''}">{{params_map['gender']?params_map['gender'][scope.row.gender].value:''}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="阵营" prop="camp">
                    <template #default="scope">
                        <span :style="{color:params_map['camp']?params_map['camp'][scope.row.camp].color:''}">{{params_map['camp']?params_map['camp'][scope.row.camp].value:''}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="会员等级" prop="level">
                    <template #default="scope">
                        <span :style="{color:params_map['user_level']?params_map['user_level'][scope.row.level].color:''}">{{params_map['user_level']?params_map['user_level'][scope.row.level].value:''}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="地区">
                    <template #default="scope">
                        <span>{{scope.row.country}} {{scope.row.province}} {{scope.row.city}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="登录" prop="logins"></el-table-column>
<!--                <el-table-column label="操作">-->
<!--                    <template #default="scope">-->
<!--                        <el-button type="text" plain size="mini" @click="onRowViewTap(scope.row)">详情</el-button>-->
<!--                        <el-button type="text" size="mini" @click="onSendCouponTap(scope.row)">发送优惠券</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
            <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page_option.page"
                        :page-sizes="[5,10, 20, 30, 50,100,200]"
                        :page-size="page_option.size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page_option.total"
                ></el-pagination>
            </div>
        </div>
        <div style="display:flex;flex-direction:row;justify-content:flex-end;align-items: center;margin-top:15px;">
            <el-button @click="handleCancelTap" size="mini">取消</el-button>
            <el-button @click="handleOnSelectConfirmTap" size="mini" type="primary">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {getParams} from "@/api/hado/param";
    import {getList} from "@/api/hado/user";
    import {bind} from '@/api/hado/employee'
    import {formatTimestamp} from "@/utils/time";
    const param_list = ['gender','camp','user_level']
    export default {
        name: "user-select-list",
        props: {
            mode: {
                type: String,
                default: 'single'
            },//单选或多选 single  / multiple
            employeeId: {
                type: String,
                default: ''
            },
            userId: {
                type: String,
                default: '',
            },
            scene: {
                type: String,
                default: 'select',//select选择优惠券 send 发送优惠券 历史问题
            },
        },
        data(){
            return{
                loading: false,
                search_form_name: 'user_search',
                page_option: {page: 1, size: 10, total: 0},
                search_option: {},
                page_list: [],
                params: {},
                params_map_pure: {},
                params_map: {},

                selectionList:[],//多选的结果
                singleSelect:{},//单选的结果
            }
        },
        created() {
            this.initPage()
        },
        methods:{
            initPage() {
                this.initParams().then(() => {
                    this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            handleSelectionChange(val) {
                this.selectionList = val;
                console.log(this.selectionList)
            },
            handleCurrentSelectChange(row){
                console.log('handleCurrentChange')
                if(this.mode === 'single'){
                    this.singleSelect = row;
                    this.selectionList = [this.singleSelect]
                }
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            handleCancelTap(){
                this.$emit("onTellDialogClose")
            },

            handleOnSelectConfirmTap(){
                //看下这货选了没有就提示
                this.$confirm('确定绑定当前微信?',{
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                    type:'warning',
                }).then(()=>{
                    // this.$emit('onCouponSelectConfirm',{coupon_id:this.singleSelect.id})
                    bind(this.employeeId ,this.singleSelect.id).then(()=>{
                        this.$emit('onTellDialogClose',{})
                    })
                }).catch(err=>{
                    console.error(err)
                })
            },
        }
    }
</script>

<style scoped>

</style>
