import request from "@/utils/request";

export const getList = (page,size,params)=>{
    return request({
        url:'/api/hado/employee/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const getDetail = (id)=>{
    return request({
        url:'/api/hado/employee/detail',
        method:'get',
        params:{id}
    })
}

export const submit = (data)=>{
    return request({
        url:'/api/hado/employee/submit',
        method:'post',
        data:data
    })
}

export const remove = (id)=>{
    return request({
        url:'/api/hado/employee/remove',
        method:'get',
        params:{id}
    })
}

export const bind = (employee_id,user_id)=>{
    return request({
        url:'/api/hado/employee/bind',
        method:'get',
        params:{employee_id,user_id},
    })
}

export const unBind = (employee_id)=>{
    return request({
        url:'/api/hado/employee/un-bind',
        method:'get',
        params:{employee_id}
    })
}
